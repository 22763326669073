import { useEffect, useState } from "react";
import StatComponent from "./StatComponent";
import random from "../app/random";
import playAudio from "../app/playAudio";
import createNotePlayers from "../app/createNotePlayers";

function IntervalRecognitionPage() {
    const [notes, setNotes] = useState([]);
    const [selectedIntervals, setSelectedIntervals] = useState([]);
    const [message, setMessage] = useState({
        message: "",
        correct: true
    });
    const [stats, setStats] = useState({
        points: 0,
        rounds: 0
    });
    const [hideIntervals, setHideIntervals] = useState(true);
    const [listenAgain, setListenAgain] = useState(false);
    const [disableListen, setDisableListen] = useState(true);
    const [randomInterval, setRandomInterval] = useState(null);
    const [guess, setGuess] = useState(null);
    const [selectAll, setSelectAll] = useState(true);
    const [intervals, setIntervals] = useState([
        {
            name: "Minor 2nd",
            positions: [0, 1]
        },
        {
            name: "Major 2nd",
            positions: [0, 2]
        },
        {
            name: "Minor 3rd",
            positions: [0, 3]
        },
        {
            name: "Major 3rd",
            positions: [0, 4]
        },
        {
            name: "Perfect 4th",
            positions: [0, 5]
        },
        {
            name: "Tritone",
            positions: [0, 6]
        },
        {
            name: "Perfect 5th",
            positions: [0, 7]
        },
        {
            name: "Minor 6th",
            positions: [0, 8]
        },
        {
            name: "Major 6th",
            positions: [0, 9]
        },
        {
            name: "Minor 7th",
            positions: [0, 10]
        },
        {
            name: "Major 7th",
            positions: [0, 11]
        },
        {
            name: "Octave",
            positions: [0, 12]
        },
        {
            name: "Minor 9th",
            positions: [0, 13]
        },
        {
            name: "Major 9th",
            positions: [0, 14]
        }
    ]);

    const selectInterval = (interval, btn) => {
        const index = selectedIntervals.findIndex(i => i.name === interval.name);
        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-mid-grey");

        if (index === -1) {
            setSelectedIntervals([...selectedIntervals, interval]);
        } else {
            const intvls = [...selectedIntervals];
            intvls.splice(index, 1);
            setSelectedIntervals(intvls);
        }
    };

    const playInterval = () => {
        if (selectedIntervals.length < 2) {
            alert("You have to select at least two intervals!");
            return;
        }
        const interval = selectedIntervals[random(0, selectedIntervals.length - 1)];
        const offset = random(0, 8);
        const pos1 = interval.positions[0] + offset;
        const pos2 = interval.positions[1] + offset;
        const noteA = notes[pos1].source;
        const noteB = notes[pos2].source;

        setRandomInterval({
            name: interval.name,
            positions: [pos1, pos2]
        });
        playAudio(noteA);
        playAudio(noteB);

        setHideIntervals(false);
        setListenAgain(true);
    };

    const playRandomInterval = () => {
        const noteA = notes[randomInterval.positions[0]].source;
        const noteB = notes[randomInterval.positions[1]].source;
        playAudio(noteA);
        playAudio(noteB);
    };

    const sendGuess = () => {
        if (guess === null) {
            alert("Please, select a guess!");
            return;
        }
        setStats(s => ({ ...s, rounds: s.rounds + 1 }));
        setDisableListen(false);
        setListenAgain(false);
        deselectAll("interval-btn");
        setGuess(null);

        if (randomInterval.name === guess.name) {
            setMessage({
                message: "Congratulations! The answer is correct!",
                correct: true
            });

            setStats(s => ({ ...s, points: s.points + 1 }));
        } else {
            setMessage({
                message: `The correct answer is: ${randomInterval.name}`,
                correct: false
            });
        }
    };

    const deselectAll = (cls) => {
        const intervalBtns = document.querySelectorAll(`.${cls}`);

        for (const btn of intervalBtns) {
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-secondary");
        }
    };

    const guessInterval = (interval, btn) => {
        deselectAll("interval-btn");
        setGuess(interval);
        btn.classList.add("btn-primary");
        btn.classList.remove("btn-secondary");
    };

    const selectDeselect = () => {
        const intervalBtns = document.querySelectorAll(".select-interval-btn");

        if (selectAll) {
            intervalBtns.forEach(btn => {
                btn.classList.add("btn-primary");
                btn.classList.remove("btn-mid-grey");
            });
            setSelectedIntervals(intervals);
        } else {
            intervalBtns.forEach(btn => {
                btn.classList.remove("btn-primary");
                btn.classList.add("btn-mid-grey");
            });
            setSelectedIntervals([]);
        }
        setSelectAll(!selectAll);
    };

    const tellMe = () => {
        if (randomInterval) {
            setMessage({
                message: `The correct interval is: ${randomInterval.name}`,
                correct: false
            });
        }
    };

    const playNoteByNote = () => {
        if (randomInterval) {
            const noteA = notes[randomInterval.positions[0]].source;
            const noteB = notes[randomInterval.positions[1]].source;
            playAudio(noteA);
            setTimeout(() => playAudio(noteB), 1000);  // 1 másodperces késleltetés a két hang között
        }
    };

    useEffect(() => {
        createNotePlayers(setNotes);
    }, []);

    useEffect(() => {
        setDisableListen(selectedIntervals.length === 0);
    }, [selectedIntervals]);

    return (
        <div className="container-lg text-center">
            <h1>Interval recognition</h1>

            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 box-light-grey table-border p-md">
                    <h2>Select intervals!</h2>

                    <div className="row">
                        {
                            intervals.map((interval, i) =>
                                <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                                    <button onClick={(e) => selectInterval(interval, e.target)}
                                        className="btn-sm btn-mid-grey minw-80 select-interval-btn">
                                        {interval.name}
                                    </button>
                                </div>
                            )
                        }
                    </div>

                    <button onClick={selectDeselect} className={"btn-md " + (!selectAll ? "btn-warning" : "btn-secondary")}>
                        {selectAll ? "Select all!" : "Deselect all!"}
                    </button>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 box-light-grey table-border p-md">
                    <h2>Guess the interval!</h2>
                    <h3>Statistics</h3>
                    <div className={"bold-text mb-lg " + (message.correct ? "color-primary" : "color-warning")}>
                        {message.message}
                    </div>

                    <StatComponent rounds={stats.rounds} points={stats.points} />

                    <h3>Choose your guess!</h3>
                    <div className="row jc-center p-sm wp-100 bg-white mt-lg minh-75 rounded-5 position-relative">
                        <div className={"modal " + (!hideIntervals && "d-none")}></div>
                        {
                            selectedIntervals.map((interval, i) =>
                                <div key={i} className="col-lg-2 col-md-3 col-sm-4 col-xs-6 p-sm d-flex jc-center">
                                    <button onClick={(e) => guessInterval(interval, e.target)}
                                        className="btn-sm btn-secondary minw-80 interval-btn">{interval.name}</button>
                                </div>
                            )
                        }
                    </div>

                    {
                        !listenAgain ?
                            <button onClick={playInterval} disabled={disableListen}
                                className={"btn-md btn-secondary " + (disableListen ? "btn-mid-grey" : "")}>
                                Listen!
                            </button>
                            :
                            <div className="d-flex jc-center">
                                <button onClick={playRandomInterval}
                                    className="btn-md btn-secondary m-md">Listen again!</button>

                                <button onClick={sendGuess} disabled={guess === null}
                                    className={"btn-md m-md " + (guess === null ? "btn-mid-grey" : "btn-secondary")}>
                                    Send!
                                </button>
                            </div>
                    }

                    <h2>Help me, please!</h2>
                    <div className="row mt-xl">
                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Tell me!</h3>
                            <button onClick={tellMe} className="btn-md btn-error center-input">Tell me!</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Play note-by-note!</h3>
                            <button onClick={playNoteByNote} className="btn-md btn-error center-input">Play!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IntervalRecognitionPage;