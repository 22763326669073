import './styles/style.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PerfectPitchPage from './components/PerfectPitchPage';
import ChordRecognitionPage from './components/ChordRecognitionPage';
import Nav from './components/Nav';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faHouse } from '@fortawesome/free-solid-svg-icons';
import IntervalRecognitionPage from './components/IntervalRecognitionPage';
import ScaleRecognitionPage from './components/ScaleRecognitionPage';
import HomePage from './components/HomePage';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import ScaleDegreePage from './components/ScaleDegreePage';
import MusicSheet from './components/MusicSheet';
library.add(
    faChevronDown,
    faHouse,
    faFacebook,
    faYoutube
);

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Nav/>}>
                    <Route index element={<HomePage/>}/>
                    <Route path='/interval-recognition' element={<IntervalRecognitionPage/>}/>
                    <Route path='/scale-recognition' element={<ScaleRecognitionPage/>}/>
                    <Route path='/chord-recognition' element={<ChordRecognitionPage/>}/>
                    <Route path='/perfect-pitch' element={<PerfectPitchPage/>}/>
                    <Route path='/scale-degree' element={<ScaleDegreePage/>}/>
                    <Route path="/music-sheet" element={<MusicSheet/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;