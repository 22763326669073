function StatComponent({rounds, points}) {
    return (
        <div className="row wp-100 color-white bold-text rounded-5">
            <div className="box-primary col-lg-6 col-md-6 col-sm-6 col-xs-6 table-border p-md">
                rounds: {rounds}
            </div>
            <div className="box-primary col-lg-6 col-md-6 col-sm-6 col-xs-6 table-border p-md">
                points: {points}
            </div>
        </div>

    );
}

export default StatComponent;