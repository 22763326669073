const audioContext = new (window.AudioContext || window.webkitAudioContext)();

async function playAudio(url) {
    try {
        // Lekérjük az audio fájlt az adott URL-ről
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();

        // Dekódoljuk az audio adatokat
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Létrehozunk egy audio buffer forrást
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;

        // Csatlakoztatjuk a forrást a lejátszóhoz (destination)
        source.connect(audioContext.destination);

        // Lejátsszuk az audio fájlt
        setTimeout(()=> {
            source.start();
        }, 200);

        // Visszaadjuk a forrást, hogy később meg lehessen állítani, ha szükséges
        return source;
    } catch (error) {
        console.error('Hiba történt az audio fájl lejátszása közben:', error);
    }
}

export default playAudio;