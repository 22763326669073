import { useEffect, useState } from "react";
import StatComponent from "./StatComponent";
import random from "../app/random";
import playAudio from "../app/playAudio";
import createNotePlayers from "../app/createNotePlayers";

function ScaleRecognitionPage() {
    const [notes, setNotes] = useState([]);
    const [selectedScales, setSelectedScales] = useState([]);
    const [message, setMessage] = useState({
        message: "",
        correct: true
    });
    const [stats, setStats] = useState({
        points: 0,
        rounds: 0
    });
    const [hideScales, setHideScales] = useState(true);
    const [listenAgain, setListenAgain] = useState(false);
    const [disableListen, setDisableListen] = useState(true);
    const [randomScale, setRandomScale] = useState(null);
    const [guess, setGuess] = useState(null);
    const [selectAll, setSelectAll] = useState(true);
    const [scales, setScales] = useState([
        { name: "Major", positions: [0, 2, 4, 5, 7, 9, 11, 12] },
        { name: "Natural Minor", positions: [0, 2, 3, 5, 7, 8, 10, 12] },
        { name: "Harmonic Minor", positions: [0, 2, 3, 5, 7, 8, 11, 12] },
        { name: "Melodic Minor", positions: [0, 2, 3, 5, 7, 9, 11, 12] },
        { name: "Dorian", positions: [0, 2, 3, 5, 7, 9, 10, 12] },
        { name: "Phrygian", positions: [0, 1, 3, 5, 7, 8, 10, 12] },
        { name: "Lydian", positions: [0, 2, 4, 6, 7, 9, 11, 12] },
        { name: "Mixolydian", positions: [0, 2, 4, 5, 7, 9, 10, 12] },
        { name: "Locrian", positions: [0, 1, 3, 5, 6, 8, 10, 12] }
    ]);

    const selectScale = (scale, btn) => {
        const index = selectedScales.findIndex(s => s.name === scale.name);
        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-mid-grey");

        if (index === -1) {
            setSelectedScales([...selectedScales, scale]);
        } else {
            const scs = [...selectedScales];
            scs.splice(index, 1);
            setSelectedScales(scs);
        }
    };

    const playScale = () => {
        if (selectedScales.length < 1) {
            alert("You have to select at least one scale!");
            return;
        }
        const scale = selectedScales[random(0, selectedScales.length - 1)];
        const offset = random(0, 8);
        const positions = scale.positions.map(pos => pos + offset);

        setRandomScale({
            name: scale.name,
            positions
        });

        positions.forEach((pos, i) => {
            setTimeout(() => playAudio(notes[pos].source), i * 500);
        });

        setHideScales(false);
        setListenAgain(true);
    };

    const playRandomScale = () => {
        randomScale.positions.forEach((pos, i) => {
            setTimeout(() => playAudio(notes[pos].source), i * 500);
        });
    };

    const sendGuess = () => {
        if (guess === null) {
            alert("Please, select a guess!");
            return;
        }
        setStats(s => ({ ...s, rounds: s.rounds + 1 }));
        setDisableListen(false);
        setListenAgain(false);
        deselectAll();
        setGuess(null);

        if (randomScale.name === guess.name) {
            setMessage({
                message: "Congratulations! The answer is correct!",
                correct: true
            });

            setStats(s => ({ ...s, points: s.points + 1 }));
        } else {
            setMessage({
                message: `The correct answer is: ${randomScale.name}`,
                correct: false
            });
        }
    };

    const deselectAll = () => {
        const scaleBtns = document.querySelectorAll(".scale-btn");

        for (const btn of scaleBtns) {
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-secondary");
        }
    };

    const guessScale = (scale, btn) => {
        deselectAll();
        setGuess(scale);
        btn.classList.add("btn-primary");
        btn.classList.remove("btn-secondary");
    };

    const selectDeselect = () => {
        const scaleBtns = document.querySelectorAll(".select-scale-btn");

        if (selectAll) {
            scaleBtns.forEach(btn => {
                btn.classList.add("btn-primary");
                btn.classList.remove("btn-mid-grey");
            });
            setSelectedScales(scales);
        } else {
            scaleBtns.forEach(btn => {
                btn.classList.remove("btn-primary");
                btn.classList.add("btn-mid-grey");
            });
            setSelectedScales([]);
        }
        setSelectAll(!selectAll);
    };

    const tellMe = () => {
        if (randomScale) {
            setMessage({
                message: `The correct scale is: ${randomScale.name}`,
                correct: true
            });
        }
    };

    const playNoteByNote = () => {
        if (randomScale) {
            randomScale.positions.forEach((pos, i) => {
                setTimeout(() => playAudio(notes[pos].source), i * 1000);  // 1 másodperces késleltetés minden hang között
            });
        }
    };

    useEffect(() => {
        createNotePlayers(setNotes);
    }, []);

    useEffect(() => {
        setDisableListen(selectedScales.length === 0);
    }, [selectedScales]);

    return (
        <div className="container-lg text-center">
            <h1>Scale Recognition</h1>

            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 box-light-grey table-border p-md">
                    <h2>Select scales!</h2>

                    <div className="row">
                        {
                            scales.map((scale, i) =>
                                <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                                    <button onClick={(e) => selectScale(scale, e.target)}
                                        className="btn-sm btn-mid-grey minw-110 select-scale-btn">
                                        {scale.name}
                                    </button>
                                </div>
                            )
                        }
                    </div>

                    <button onClick={selectDeselect} className="btn-md btn-secondary">
                        {selectAll ? "Select all!" : "Deselect all!"}
                    </button>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 box-light-grey table-border p-md">
                    <h2>Guess the scale!</h2>
                    <h3>Statistics</h3>
                    <div className={"bold-text mb-lg " + (message.correct ? "color-primary" : "color-warning")}>
                        {message.message}
                    </div>

                    <StatComponent rounds={stats.rounds} points={stats.points} />

                    <h3>Choose your guess!</h3>
                    <div className="row jc-center p-sm wp-100 bg-white mt-lg minh-75 rounded-5 position-relative">
                        <div className={"modal " + (!hideScales && "d-none")}></div>
                        {
                            selectedScales.map((scale, i) =>
                                <div key={i} className="col-lg-2 col-md-3 col-sm-4 col-xs-6 p-sm d-flex jc-center">
                                    <button onClick={(e) => guessScale(scale, e.target)}
                                        className="btn-sm btn-secondary minw-80 scale-btn">{scale.name}</button>
                                </div>
                            )
                        }
                    </div>

                    {
                        !listenAgain ?
                            <button onClick={playScale} disabled={disableListen}
                                className={"btn-md btn-secondary " + (disableListen ? "btn-mid-grey" : "")}>
                                Listen!
                            </button>
                            :
                            <div className="d-flex jc-center">
                                <button onClick={playRandomScale}
                                    className="btn-md btn-secondary m-md">Listen again!</button>

                                <button onClick={sendGuess} disabled={guess === null}
                                    className={"btn-md m-md " + (guess === null ? "btn-mid-grey" : "btn-secondary")}>
                                    Send!
                                </button>
                            </div>
                    }


                    <h2>Help me, please!</h2>
                    <div className="row mt-xl">
                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Tell me!</h3>
                            <button onClick={tellMe} className="btn-md btn-error center-input">Tell me!</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Play slower!</h3>
                            <button onClick={playNoteByNote} className="btn-md btn-error center-input">Play slower!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScaleRecognitionPage;