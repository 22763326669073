import { Link, Outlet, useLocation } from "react-router-dom";
import SelectMenu from "../app/selectMenu";

function Nav() {
    const location = useLocation();

    return(
        <>
            <nav>
                <ul>
                    <li className={SelectMenu("/", location.pathname)}>
                        <Link to="/">Home</Link>
                    </li>
                    <li className={SelectMenu("/interval-recognition", location.pathname)}>
                        <Link to="/interval-recognition">Interval recognition</Link>
                    </li>
                    <li className={SelectMenu("/scale-recognition", location.pathname)}>
                        <Link to="/scale-recognition">Scale recognition</Link>
                    </li>
                    <li className={SelectMenu("/chord-recognition", location.pathname)}>
                        <Link to="/chord-recognition">Chord recognition</Link>
                    </li>
                    <li className={SelectMenu("/scale-degree", location.pathname)}>
                        <Link to="/scale-degree">Scale degree</Link>
                    </li>
                    <li className={SelectMenu("/perfect-pitch", location.pathname)}>
                        <Link to="/perfect-pitch">Perfect pitch</Link>
                    </li>
                </ul>
            </nav>

            <Outlet/>
        </>
    );
}

export default Nav;