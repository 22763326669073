import { useEffect, useState } from "react";
import random from "../app/random";

function PerfectPitchPage() {
    const [disableNext, setDisableNext] = useState(false);
    const [disableLitenTo, setDisableListenTo] = useState(false);
    const [randomNote, setRandomNote] = useState(null);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [stats, setStats] = useState({
        points: 0,
        rounds: 0
    });

    const [notes, setNotes] = useState([]);
    const [selectAllActive, setSelectAllActive] = useState(false);
    const [disablePlay, setDisablePlay] = useState(false);

    const createNotePlayers = () => {
        const ns = [];

        for (const note of ["C2", "CS2", "D2", "DS2", "E2", "F2", "FS2", "G2", "GS2", "A2", "AS2", "B2", "C3", "CS3", "D3", "DS3", "E3", "F3", "FS3", "G3", "GS3", "A3", "AS3", "B3", "C4"]) {
            const src = require(`../sounds/piano/${note}.mp3`);
            const notePlayer = new Audio();
            notePlayer.src = src;
            ns.push({ note: note, player: notePlayer });
        }

        setNotes(ns);
    };

    const playRandomNote = () => {
        if (selectedKeys.length < 2) {
            alert("You have to select at least two notes!");
            return;
        }
        const rand = random(0, selectedNotes.length - 1);

        const btns = document.querySelectorAll(".note-btn");

        for (const btn of btns) {
            btn.classList.remove("btn-warning");
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-secondary");
        }

        selectedNotes[rand]["player"].play();
        setDisableNext(true);

        setRandomNote({
            player: selectedNotes[rand]["player"],
            note: selectedNotes[rand]["note"]
        });
    };

    const playSelectedNote = () => {
        if (randomNote.player)
            randomNote.player.play();

        setDisableListenTo(true);

        setTimeout(() => {
            setDisableListenTo(false);
        }, 1000);
    };

    const guessNote = (note, btn) => {
        const cleanedNote = randomNote.note.substr(0, 2).replace("S", "#").replace(/[\d]/g, "");
        setStats((s) => ({ ...s, rounds: s.rounds + 1 }));

        if (note === cleanedNote) {
            setStats((s) => ({ ...s, points: s.points + 1 }));
            btn.classList.add("btn-primary");
            btn.classList.remove("btn-secondary");
        } else {
            btn.classList.add("btn-warning");
            btn.classList.remove("btn-secondary");
        }

        setDisableNext(false);
    };

    const getSpecificNotes = (selected) => {
        const sNotes = [];

        for (const note of notes) {
            const cleanedNote = note.note.replaceAll("S", "#").replace(/[\d]/g, "");

            if (selected === cleanedNote)
                sNotes.push(note);
        }

        return sNotes;
    };

    const selectNote = (btn, note) => {
        const sNotes = getSpecificNotes(note);

        if (!btn.classList.contains("btn-primary")) {

            setSelectedNotes([...selectedNotes, ...sNotes]);
            setSelectedKeys([...selectedKeys, note]);
        } else {
            const noteNames = sNotes.map(sn => sn.note);
            let selected = [...selectedNotes];

            for (const noteName of noteNames)
                selected = selected.filter(s => s.note !== noteName);

            setSelectedNotes(selected);
            setSelectedKeys(selectedKeys.filter(sk => sk !== note));
        }

        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-mid-grey");
    };

    const selectAllNotes = () => {
        if (!selectAllActive) {
            const allNotes = [];
            const allKeys = [];

            for (const note of notesArr) {
                allNotes.push(...getSpecificNotes(note));
                allKeys.push(note);
            }

            setSelectedNotes(allNotes);
            setSelectedKeys(allKeys);

            const btns = document.querySelectorAll(".note-select-btn");

            for (const btn of btns) {
                btn.classList.add("btn-primary");
                btn.classList.remove("btn-mid-grey");
            }

            setSelectAllActive(true);
        } else {
            deselectAll();
        }
    };

    const deselectAll = () => {
        const btns = document.querySelectorAll(".note-select-btn");

        for (const btn of btns) {
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-mid-grey");
        }

        setSelectedNotes([]);
        setSelectedKeys([]);
        setSelectAllActive(false);
    };

    const tellMe = () => {
        if (randomNote) {
            alert(`The note is: ${randomNote.note}`);
        } else {
            alert("No note has been played yet.");
        }
    };

    const playC = () => {
        const player = notes.find(n=>n.note === "C3").player;
        player.play();

        setDisablePlay(true);

        setTimeout(()=> {
            setDisablePlay(false);
        }, 1000);
    };

    const notesArr = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

    useEffect(() => {
        createNotePlayers();
    }, []);

    return (
        <div className="container-md text-center">
            <h1>Perfect Pitch Quiz</h1>
            <div className="row">
                <div className="col-lg-9 p-xl box-light-grey table-border">
                    <h2>Guess the note!</h2>
                    <h3>Statistics</h3>

                    <div className="row wp-100 color-white bold-text rounded-5">
                        <div className="box-primary col-lg-6 col-md-6 table-border p-md">
                            rounds: {stats.rounds}
                        </div>
                        <div className="box-primary col-lg-6 col-md-6 table-border p-md">
                            points: {stats.points}
                        </div>
                    </div>

                    <h3>Selected notes</h3>
                    <div className="row p-sm wp-100 bg-white mt-lg minh-75 rounded-5">
                        {
                            selectedKeys.map((key, i) =>
                                <div key={i} className="col-lg-1 col-md-2 col-sm-3 col-xs-4 p-sm">
                                    <button onClick={(e) => guessNote(key, e.target)}
                                        className="btn-sm btn-secondary minw-30 note-btn">{key}</button>
                                </div>
                            )
                        }
                    </div>

                    {
                        !disableNext ?
                            <button onClick={playRandomNote} disabled={disableNext}
                                className={"btn-md btn-primary center-input "}>Next</button>
                            : <button onClick={playSelectedNote}
                                className={"btn-md btn-primary center-input " + (disableLitenTo && "btn-mid-grey")}>Listen to again!</button>
                    }

                    <h2>Help me, please!</h2>
                    <div className="row mt-xl">
                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Tell me!</h3>
                            <button className="btn-md btn-error center-input" onClick={tellMe}>Tell me!</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Play a C!</h3>
                            <button onClick={playC} disabled={disablePlay}
                            className={"btn-md center-input " + (disablePlay ? "btn-mid-grey" : "btn-error")}>Play!</button>
                        </div>
                    </div>

                </div>
                <div className="col-lg-3 box-light-grey p-xl table-border">
                    <h3>Select notes!</h3>
                    <div className="row">
                        {
                            notesArr.map((note, i) =>
                                <div key={i} className="col-lg-6 col-md-3 col-sm-4 col-xs-4">
                                    <button key={i} onClick={(e) => selectNote(e.target, note)}
                                    className="btn-sm btn-mid-grey center-input minw-30 mw-50 note-select-btn">{note}</button>
                                </div>
                            )
                        }
                    </div>

                    <button className={"btn-md center-input mt-lg " 
                    + (!selectAllActive ? "btn-primary" : "btn-warning")} 
                    onClick={selectAllNotes}>
                        {!selectAllActive ? "Select all!" : "Deselect all!"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PerfectPitchPage;