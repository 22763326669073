import React, { useEffect, useRef, useState } from 'react';
import Vex from 'vexflow';

const MusicSheet = () => {
    const containerRef = useRef(null);
    const [selectedNote, setSelectedNote] = useState(0);

    useEffect(() => {
        const VF = Vex.Flow;
        const div = containerRef.current;
        const renderer = new VF.Renderer(div, VF.Renderer.Backends.SVG);
        renderer.resize(800, 200); // Méret növelése, hogy elférjen két ütem
        const context = renderer.getContext();

        // Az első ütem
        const stave1 = new VF.Stave(10, 40, 350); // Az első ütem szélessége
        stave1.addClef("treble").setContext(context).draw();

        // A második ütem
        const stave2 = new VF.Stave(stave1.width + stave1.x, 40, 350); // A második ütem szélessége és pozíciója
        stave2.setContext(context).draw();

        // Hangjegyek
        const notes = [
            new VF.StaveNote({ clef: "treble", keys: ["c/4"], duration: "q", id: 0 }),
            new VF.StaveNote({ clef: "treble", keys: ["d/4"], duration: "q", id: 1 }),
            new VF.StaveNote({ clef: "treble", keys: ["e/4"], duration: "q", id: 2 }),
            new VF.StaveNote({ clef: "treble", keys: ["f/4"], duration: "q", id: 3 }),
            new VF.StaveNote({ clef: "treble", keys: ["g/4"], duration: "q", id: 4 }),
            new VF.StaveNote({ clef: "treble", keys: ["a/4"], duration: "q", id: 5 }),
            new VF.StaveNote({ clef: "treble", keys: ["b/4"], duration: "q", id: 6 }),
            new VF.StaveNote({ clef: "treble", keys: ["c/5"], duration: "q", id: 7 }),
        ];

        // Két külön voice, minden ütemhez egy-egy
        const voice1 = new VF.Voice({ num_beats: 4, beat_value: 4 });
        voice1.addTickables(notes.slice(0, 4));

        const voice2 = new VF.Voice({ num_beats: 4, beat_value: 4 });
        voice2.addTickables(notes.slice(4, 8));

        // Formatter beállítása
        new VF.Formatter().joinVoices([voice1]).format([voice1], 350);
        new VF.Formatter().joinVoices([voice2]).format([voice2], 350);

        // A voice-ok megjelenítése
        voice1.draw(context, stave1);
        voice2.draw(context, stave2);

        // Bar line-ok hozzáadása
        stave1.setEndBarType(VF.Barline.type.DOUBLE);
        stave2.setEndBarType(VF.Barline.type.END);

        stave1.draw();
        stave2.draw();

        // Kattinthatóvá tenni a hangjegyeket
        const svg = div.querySelector('svg');
        const noteElements = svg.querySelectorAll('.vf-stavenote');
        noteElements[1].setAttribute("fill", "red");

        noteElements.forEach((element, index) => {
            element.addEventListener('click', () => {
                setSelectedNote(index);
            });
        });
    }, []);

    useEffect(() => {
        if (selectedNote !== null) {
            // const svg = containerRef.current.querySelector('svg');
            // const noteElements = svg.querySelectorAll('.vf-note');

            // noteElements.forEach((element, index) => {
            //     console.log(selectedNote === index);
            //     if (index === selectedNote) {
            //         element.setAttribute('fill', 'red');
            //     } else {
            //         element.removeAttribute('fill');
            //     }
            // });
        }
    }, [selectedNote]);

    return <div ref={containerRef}></div>;
};

export default MusicSheet;