import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HomePage() {
    return (
        <div className="container-lg text-center box-light-grey p-md">
            <h1>Welcome to Ear Genius!</h1>
            <p>
            My name is Olivér Kovács, I'm a Hungarian composer and programmer. 
            I designed this site to develop your ears and improve your musical abilities, 
            like interval, scale, and chord recognition.
            </p>
            <p>
                Recognizing scales, intervals, and chords by listening is crucial to being a 
                better musician. These abilities help you to compose better music, and of 
                course, it's important to understand basic music theory well. <br/>
                <span className="bold-text">
                    Practice every day a little bit, and you'll be a better musician!
                </span>
            </p>
            <div className="p-md">
                <h2>Check my pages!</h2>

                <div className="d-flex jc-space-evenly flex-wrap-wrap mw-500 margin-auto">
                    <h4 className="font-22">
                        <a target="_blank" href="https://oliverkovacs.eu">
                            <FontAwesomeIcon className="color-secondary"
                            icon="fa-solid fa-house" />
                        </a>
                    </h4>

                    <h4 className="font-22">
                        <a target="_blank" href="https://www.facebook.com/olivercomposer">
                            <FontAwesomeIcon className="color-secondary"
                                icon="fa-brands fa-facebook" />
                        </a>
                    </h4>

                    <h4 className="font-22">
                        <a target="_blank" href="https://www.youtube.com/@olivercomposer">
                            <FontAwesomeIcon className="color-secondary"
                            icon="fa-brands fa-youtube" />
                        </a>
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default HomePage;