const createNotePlayers = (setNotes) => {
    const ns = [];

    for (const note of ["C2", "CS2", "D2", "DS2", "E2", "F2", "FS2", "G2", 
    "GS2", "A2", "AS2", "B2", "C3", "CS3", "D3", "DS3", "E3", "F3", 
    "FS3", "G3", "GS3", "A3", "AS3", "B3", "C4"]) {
        const src = require(`../sounds/piano/${note}.mp3`);
        const notePlayer = new Audio();
        notePlayer.src = src;
        ns.push({ note: note, player: notePlayer, source:src });
    }

    setNotes(ns);
};

export default createNotePlayers;