import { useEffect, useState } from "react";
import StatComponent from "./StatComponent";
import random from "../app/random";
import playAudio from "../app/playAudio";
import createNotePlayers from "../app/createNotePlayers";

function ScaleDegreePage() {
    const [notes, setNotes] = useState([]);
    const [selectedDegrees, setSelectedDegrees] = useState([]);
    const [message, setMessage] = useState({
        message: "",
        correct: true
    });
    const [stats, setStats] = useState({
        points: 0,
        rounds: 0
    });
    const [hideDegrees, setHideDegrees] = useState(true);
    const [listenAgain, setListenAgain] = useState(false);
    const [disableListen, setDisableListen] = useState(true);
    const [randomDegree, setRandomDegree] = useState(null);
    const [guess, setGuess] = useState(null);
    const [selectAll, setSelectAll] = useState(true);
    const [degrees, setDegrees] = useState([
        "I. do", 
        "I. raised (di)", 
        "II. re", 
        "II. raised (ri)", 
        "III. mi", 
        "IV. fa", 
        "IV. raised (fi)", 
        "V. so", 
        "V. raised (si)", 
        "VI. la", 
        "VI. raised (li)", 
        "VII. ti"
    ]);

    const selectDegree = (degree, btn) => {
        const index = selectedDegrees.findIndex(d => d === degree);
        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-mid-grey");

        if (index === -1) {
            setSelectedDegrees([...selectedDegrees, {
                name: degree,
                index: degrees.findIndex(d => d === degree)
            }]);
        } else {
            const sDegrees = [...selectedDegrees];
            sDegrees.splice(index, 1);
            setSelectedDegrees(sDegrees);
        }
    };

    const playIIVVAgain = async ()=> {
        if(randomDegree === null) {
            alert("You have to press the 'listen' button first!");
            return;
        }
        await playAudio(notes[randomDegree.notesArr[0]].source);
        await playAudio(notes[randomDegree.notesArr[0] + 4].source);
        await playAudio(notes[randomDegree.notesArr[0] + 7].source);

        let index = 1;

        const intervalID = setInterval(async () => {
            await playAudio(notes[randomDegree.notesArr[index]].source);
            await playAudio(notes[randomDegree.notesArr[index] + 4].source);
            await playAudio(notes[randomDegree.notesArr[index] + 7].source);

            index++;

            if (index === randomDegree.notesArr.length)
                clearInterval(intervalID);
        }, 1000);
    };

    const playIIVV = async () => {
        if (selectedDegrees.length < 1) {
            alert("You have to select at least one degree!");
            return;
        }

        const offset = random(0, 8);
        const note = selectedDegrees[random(0, selectedDegrees.length - 1)];
        const noteIndex = note.index + offset;

        const notesArr = [
            offset, 5 + offset, 
            7 + offset, offset
        ];

        setRandomDegree({
            name: note.name,
            index: noteIndex,
            notesArr:notesArr
        });

        await playAudio(notes[offset].source);
        await playAudio(notes[offset + 4].source);
        await playAudio(notes[offset + 7].source);

        let index = 1;

        const intervalID = setInterval(async () => {
            await playAudio(notes[notesArr[index]].source);
            await playAudio(notes[notesArr[index] + 4].source);
            await playAudio(notes[notesArr[index] + 7].source);

            index++;

            if (index === notesArr.length)
                clearInterval(intervalID);
        }, 1000);

        setTimeout(async () => {
            await playAudio(notes[noteIndex].source);

            setHideDegrees(false);
            setListenAgain(true);
        }, 4000);
    };

    const playRandomDegree = () => {
        const note = notes[randomDegree.index].source;
        playAudio(note);
    };

    const sendGuess = () => {
        if (guess === null) {
            alert("Please, select a guess!");
            return;
        }
        setStats(s => ({ ...s, rounds: s.rounds + 1 }));
        setDisableListen(false);
        setListenAgain(false);
        deselectAll("degree-btn");
        setGuess(null);
        setRandomDegree(null);

        if (randomDegree.name === guess.name) {
            setMessage({
                message: "Congratulations! The answer is correct!",
                correct: true
            });

            setStats(s => ({ ...s, points: s.points + 1 }));
        } else {
            setMessage({
                message: `The correct answer is: ${randomDegree.name}`,
                correct: false
            });
        }
    };

    const deselectAll = (cls) => {
        const degreeBtns = document.querySelectorAll(`.${cls}`);

        for (const btn of degreeBtns) {
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-secondary");
        }
    };

    const guessDegree = (degree, btn) => {
        deselectAll("degree-btn");
        setGuess(degree);
        btn.classList.add("btn-primary");
        btn.classList.remove("btn-secondary");
    };

    const selectDeselect = () => {
        const degreeBtns = document.querySelectorAll(".select-degree-btn");

        if (selectAll) {
            degreeBtns.forEach(btn => {
                btn.classList.add("btn-primary");
                btn.classList.remove("btn-mid-grey");
            });
            setSelectedDegrees(degrees.map((degree, index) => ({ name: degree, index })));
        } else {
            degreeBtns.forEach(btn => {
                btn.classList.remove("btn-primary");
                btn.classList.add("btn-mid-grey");
            });
            setSelectedDegrees([]);
        }
        setSelectAll(!selectAll);
    };

    const tellMe = () => {
        if (randomDegree) {
            setMessage({
                message: `The correct degree is: ${randomDegree.name}`,
                correct: false
            });
        }
    };

    const playNoteByNote = () => {
        if (randomDegree) {
            const note = notes[randomDegree.index].source;
            playAudio(note);
        }
    };

    useEffect(() => {
        createNotePlayers(setNotes);
    }, []);

    useEffect(() => {
        setDisableListen(selectedDegrees.length === 0);
    }, [selectedDegrees]);

    return (
        <div className="container-lg text-center">
            <h1>Scale Degree Quiz</h1>

            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 box-light-grey table-border p-md">
                    <h2>Select degrees!</h2>

                    <div className="row">
                        {degrees.map((degree, i) => (
                            <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                                <button onClick={(e) => selectDegree(degree, e.target)}
                                    className="btn-sm btn-mid-grey minw-100 select-degree-btn">
                                    {degree}
                                </button>
                            </div>
                        ))}
                    </div>

                    <button onClick={selectDeselect} className={"btn-md " + (!selectAll ? "btn-warning" : "btn-secondary")}>
                        {selectAll ? "Select all!" : "Deselect all!"}
                    </button>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 box-light-grey table-border p-md">
                    <h2>Guess the scale degree!</h2>
                    <h3>Statistics</h3>
                    <div className={"bold-text mb-lg " + (message.correct ? "color-primary" : "color-warning")}>
                        {message.message}
                    </div>

                    <StatComponent rounds={stats.rounds} points={stats.points} />

                    <h3>Choose your guess!</h3>
                    <div className="row jc-center p-sm wp-100 bg-white mt-lg minh-75 rounded-5 position-relative">
                        <div className={"modal " + (!hideDegrees && "d-none")}></div>
                        {selectedDegrees.map((degree, i) => (
                            <div key={i} className="col-lg-2 col-md-3 col-sm-4 col-xs-6 p-sm d-flex jc-center">
                                <button onClick={(e) => guessDegree(degree, e.target)}
                                    className="btn-sm btn-secondary minw-80 degree-btn">{degree.name}</button>
                            </div>
                        ))}
                    </div>

                    {!listenAgain ? (
                        <button onClick={playIIVV} disabled={disableListen}
                            className={"btn-md btn-secondary " + (disableListen ? "btn-mid-grey" : "")}>
                            Listen!
                        </button>
                    ) : (
                        <div className="d-flex jc-center">
                            <button onClick={playRandomDegree}
                                className="btn-md btn-secondary m-md">Listen again!</button>

                            <button onClick={sendGuess} disabled={guess === null}
                                className={"btn-md m-md " + (guess === null ? "btn-mid-grey" : "btn-secondary")}>
                                Send!
                            </button>
                        </div>
                    )}

                    <h2>Help me, please!</h2>
                    <div className="row mt-xl">
                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Tell me!</h3>
                            <button onClick={tellMe} className="btn-md btn-error center-input">Tell me!</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Play the chords again!</h3>
                            <button onClick={playIIVVAgain} className="btn-md btn-error center-input">Play!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScaleDegreePage;